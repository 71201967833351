import {
  Alert,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/joy';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { TenantContext } from '../../../../pages/ServiceOffering';
import { getServiceOfferingQueryKey } from '../../../../state/api/serviceOffering';
import { postSBBServiceBookRouteMutation } from '../../../../state/api/services/sbb';
import { ServiceContext, ServiceOfferingContext } from '../../Services';
import { SBBCard } from '../SBBCard';
import { TripContext } from '../SBBService';
import Timetable from '../Timetable';
import TravelSegments from '../TravelSegments';
import { getSbbErrorCode, getSbbErrorMessage } from './SbbErrorCode';

const InnerForm = styled('div')`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: end;
  gap: 0.5rem 1rem;
  flex-wrap: wrap;
`;

export function ConfirmTripCard() {
  const serviceOffering = useContext(ServiceOfferingContext);
  const tenant = useContext(TenantContext);
  const { dateTime } = serviceOffering;
  const { data: serviceData } = useContext(ServiceContext);
  const serviceEnabledReturnTicket = typeof serviceData.returnTicket === 'boolean' && serviceData.returnTicket;
  const { trip } = useContext(TripContext);
  const { key: tenantKey, optionalReturnTicket: tenantEnabledReturnTicket } = useContext(TenantContext);
  const displayReturnTicketOption = serviceEnabledReturnTicket || tenantEnabledReturnTicket;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ hasHalfFare: 'yes' | 'no'; returnTicket: boolean; acceptsTermsAndConditions: boolean }>();
  const { id: serviceKey } = useContext(ServiceContext);
  const queryClient = useQueryClient();
  const orderMutation = useMutation(
    postSBBServiceBookRouteMutation(
      {
        tenantKey,
        serviceKey,
        tripId: trip?.tripId ?? '',
        firstName: serviceOffering.data.firstName,
        lastName: serviceOffering.data.lastName,
        dateOfBirth: serviceOffering.data.birthDate,
      },
      () =>
        queryClient.invalidateQueries({
          queryKey: getServiceOfferingQueryKey(tenantKey, serviceOffering.serviceOfferingKey),
        }),
    ),
  );

  if (!trip) {
    return <Navigate to={''} replace={true} />;
  }

  const sbbError = orderMutation.isError ? getSbbErrorCode(orderMutation.error) : null;

  return (
    <SBBCard>
      <Typography>Sie haben folgende Verbindung ausgewählt:</Typography>
      <Typography textColor="neutral.600">
        Am <Typography textColor="neutral.900">{format(new Date(dateTime), 'EEEE, do MMMM yyyy')}</Typography> von{' '}
        <Typography variant="solid">{trip.segments[0].stops[0].name}</Typography> nach{' '}
        <Typography variant="solid">{trip.segments.at(-1)?.stops?.at(-1)?.name}</Typography> für Ihren Termin um{' '}
        <Typography textColor="neutral.900">{format(new Date(dateTime), 'HH:mm')} Uhr</Typography>.
      </Typography>
      <Divider />
      <Timetable trip={trip} />
      <Divider />
      <TravelSegments trip={trip} />
      <Divider />
      <form
        onSubmit={handleSubmit(({ hasHalfFare, returnTicket }) => {
          orderMutation.mutate({ hasHalfFare: hasHalfFare === 'yes', returnTicket });
        })}>
        <InnerForm>
          <FormControl error={!!errors.hasHalfFare}>
            <FormLabel>Haben Sie ein Halbtax?</FormLabel>
            <RadioGroup sx={{ m: 0 }}>
              <Radio color="neutral" value="yes" label="Ja" {...register('hasHalfFare', { required: true })} />
              <Radio color="neutral" value="no" label="Nein" {...register('hasHalfFare', { required: true })} />
            </RadioGroup>
            {errors.hasHalfFare && <FormHelperText>Bitte wählen Sie aus, ob Sie ein Halbtax haben.</FormHelperText>}
          </FormControl>
          {displayReturnTicketOption && (
            <FormControl>
              <FormLabel>Wollen Sie am gleichen Tag zurück an Ihren Ursprungsort fahren?</FormLabel>
              <Checkbox {...register('returnTicket')} label="Rückfahrtticket ebenfalls buchen" />
            </FormControl>
          )}
          <FormControl error={!!errors.acceptsTermsAndConditions}>
            <FormLabel>Allgemeine Geschäftsbedingungen</FormLabel>
            <Checkbox
              {...register('acceptsTermsAndConditions', { required: true })}
              label="Ich habe die nachfolgenden Bedingungen gelesen und stimme diesen zu"
            />
            {errors.acceptsTermsAndConditions && (
              <FormHelperText>Bitte akzeptieren Sie die Bedingungen.</FormHelperText>
            )}
            <FormHelperText>
              <Typography level="body-sm" textColor="neutral.700">
                Für diese Buchung gelten die{' '}
                <HelperTextLink href="https://www.balgrist.ch/datenschutzerklaerung/" target="_blank">
                  Datenschutzerklärung der Universitätsklinik Balgrist
                </HelperTextLink>{' '}
                sowie die{' '}
                <HelperTextLink href="https://www.sbb.ch/de/meta/legallines/agb-kauf-webshop.html" target="_blank">
                  AGB der SBB
                </HelperTextLink>
                . Für den Ticketkauf werden ihr Name, Vorname, Geburtsdatum und das Ticketdatum an die SBB übermittelt.
              </Typography>
            </FormHelperText>
          </FormControl>
          <ButtonWrapper>
            <Button onClick={() => history.back()} color="neutral" variant="soft">
              zurück zur Verbindungsauswahl
            </Button>
            <Button
              type="submit"
              loading={orderMutation.isPending}
              disabled={orderMutation.isSuccess || orderMutation.isPending}>
              Ticket final bestellen
            </Button>
          </ButtonWrapper>
          {sbbError && (
            <Alert color="danger">
              {getSbbErrorMessage(
                sbbError,
                tenant,
                'Die Bestellung konnte nicht ausgeführt werden. Bitte versuchen Sie es später noch einmal.',
              )}
            </Alert>
          )}
        </InnerForm>
      </form>
    </SBBCard>
  );
}

const HelperTextLink = styled(Link)`
  display: inline;
  text-decoration: underline;
`;
