import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Alert,
  Button,
  CircularProgress,
  accordionSummaryClasses,
  styled,
} from '@mui/joy';
import { useInfiniteQuery } from '@tanstack/react-query';
import { AnimatePresence, m } from 'framer-motion';
import { useContext, useEffect, useRef } from 'react';
import { ApiError } from '../../../api-client-gen';
import { TenantContext } from '../../../pages/ServiceOffering';
import { getSBBServiceTripsQuery } from '../../../state/api/services/sbb';
import { ServiceContext, ServiceOfferingContext } from '../Services';
import { TripContext } from './SBBService';
import Timetable from './Timetable';
import TravelSegments from './TravelSegments';
import { getSbbErrorCode, getSbbErrorMessage } from './cards/SbbErrorCode';

export default function Trips({ originId }: { originId: number }) {
  const isFirstRender = useIsFirstRender();
  const tenant = useContext(TenantContext);
  const serviceOffering = useContext(ServiceOfferingContext);
  const service = useContext(ServiceContext);
  const tripPage = useInfiniteQuery({
    ...getSBBServiceTripsQuery({
      tenantKey: tenant.key,
      serviceKey: service.id,
      originId,
      dateTime: serviceOffering.dateTime,
    }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.scrollBackContext,
    select: (data) => ({
      pages: [...data.pages].reverse(),
      pageParams: [...data.pageParams].reverse(),
    }),
    retry: (failureCount, error) => {
      if (error instanceof ApiError && error.status == 400) {
        return false;
      }

      return failureCount < 2;
    },
  });
  const { selectTrip } = useContext(TripContext);

  if (tripPage.isPending) {
    return <CircularProgress />;
  }

  if (tripPage.isError) {
    const sbbErrorCode = getSbbErrorCode(tripPage.error);

    return (
      <Alert color="danger">
        {getSbbErrorMessage(sbbErrorCode, tenant, 'Die SBB-Verbindungen konnten leider nicht geladen werden.')}
      </Alert>
    );
  }

  const { pages } = tripPage.data;

  return (
    <>
      {tripPage.hasNextPage && (
        <Button
          color="neutral"
          onClick={() => tripPage.fetchNextPage()}
          variant="soft"
          loading={tripPage.isFetchingNextPage}>
          Frühere Verbindungen anzeigen
        </Button>
      )}
      <AnimatePresence>
        <StyledAccordionGroup>
          {pages.map(({ trips }, pageIndex) => (
            <m.div
              key={pages.length - pageIndex} // keeps key the same when inserting a pages at the start
              layout
              initial={pages.length === 1 || isFirstRender ? 'visible' : 'hidden'} // don't show animation for first page and when navigating back to the page
              animate={'visible'}
              transition={{ duration: 0.4, staggerChildren: 0.08 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}>
              {trips.map((trip, tripIndex) => (
                <StyledAnimatedAccordion
                  variant="soft"
                  key={tripIndex}
                  variants={{ visible: { translateY: 0, opacity: 1 }, hidden: { translateY: -100, opacity: 0 } }}>
                  <StyledAccordionSummary>
                    <StyledTimetable trip={trip} />
                  </StyledAccordionSummary>
                  <AccordionDetails slotProps={{ content: { sx: { pt: 2 } } }} sx={{ background: 'hsl(225,33%,88%)' }}>
                    <TravelSegments trip={trip} />
                    <ChooseTripButton onClick={() => selectTrip(trip)}>Diese Verbindung auswählen</ChooseTripButton>
                  </AccordionDetails>
                </StyledAnimatedAccordion>
              ))}
            </m.div>
          ))}
        </StyledAccordionGroup>
      </AnimatePresence>
    </>
  );
}

const StyledAccordionGroup = styled(AccordionGroup)`
  margin: 0 calc(var(--Card-padding) * -1);
  --ListItem-paddingX: var(--Card-padding);
`;

const StyledAnimatedAccordion = m(styled(Accordion)`
  --ListItem-minHeight: 6rem;
`);

const StyledAccordionSummary = styled(AccordionSummary)`
  .${accordionSummaryClasses.indicator} {
    border: 1px solid var(--joy-palette-neutral-plainColor);
    border-radius: 1000px;
  }
  :hover .${accordionSummaryClasses.indicator} {
    background: var(--joy-palette-neutral-100);
  }
`;

const StyledTimetable = styled(Timetable)`
  flex: 1;
`;

const ChooseTripButton = styled(Button)`
  align-self: end;
  margin-top: 1rem;
`;

function useIsFirstRender() {
  const isFirstRenderRef = useRef(true);
  useEffect(() => void (isFirstRenderRef.current = false), []);
  return isFirstRenderRef.current;
}
